import * as React from 'react';
import { DataGrid, getGridStringOperators, GridToolbar, } from '@mui/x-data-grid';
import { getPlayers } from '../models/players';
import { useNavigate } from 'react-router-dom';
import { authProvider } from '../authentication/auth';
import { Badge, ClickAwayListener, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Convert } from '../utils/tools';
import { debounce } from "lodash";
function usePlayerData(queryOptions) {
    const [error, setError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [pageInfo, setPageInfo] = React.useState({
        totalRowCount: 100,
    });
    const navigate = useNavigate();
    React.useEffect(() => {
        setError(null);
        const fetchPlayers = async (queryOptions) => {
            if (queryOptions.page !== undefined) {
                setIsLoading(true);
                let query = `page=${queryOptions.page + 1}&page_size=${queryOptions.pageSize}`;
                if (queryOptions.sortModel) {
                    for (const sortModel of queryOptions.sortModel) {
                        query += `&${sortModel.field}_${sortModel.sort}=true`;
                    }
                }
                if (queryOptions.filterModel) {
                    for (const filterItem of queryOptions.filterModel.items) {
                        switch (filterItem.field) {
                            case 'metadata':
                                {
                                    const metadataValue = filterItem.value;
                                    if (metadataValue) {
                                        query += `&metadata[${encodeString(metadataValue.key)}]=${encodeString(metadataValue.value)}`;
                                    }
                                }
                                break;
                            default:
                                if (filterItem.value) {
                                    query += `&${filterItem.field}=${encodeString(filterItem.value)}`;
                                }
                                break;
                        }
                    }
                }
                try {
                    const response = await getPlayers(query);
                    if (response.unauthorized) {
                        const url = authProvider.getRedirectUrl(new Request(new URL(window.location.href)));
                        navigate(url);
                    }
                    const rows = response.result?.players;
                    setRows(rows || []);
                    setPageInfo({
                        totalRowCount: response.result?.count || 0,
                    });
                    setIsLoading(false);
                }
                catch (err) {
                    const error = err;
                    console.error(error);
                    setError(error);
                }
            }
        };
        fetchPlayers(queryOptions);
    }, [queryOptions, navigate]);
    return { isLoading, rows, pageInfo, error };
}
function encodeString(string) {
    return encodeURIComponent(string).replace(/#/g, '%23');
}
function MetadataInputValue(props) {
    const { item, applyValue } = props;
    const [key, setKey] = React.useState("");
    const [value, setValue] = React.useState("");
    const debouncedApplyValue = React.useRef(debounce((item) => {
        applyValue(item);
    }, 300)).current;
    React.useEffect(() => {
        return () => {
            debouncedApplyValue.cancel();
        };
    }, [debouncedApplyValue]);
    React.useEffect(() => {
        setKey(item.value?.key || '');
        setValue(item.value?.value || '');
    }, [item, setKey, setValue]);
    return (React.createElement("div", { style: { display: 'flex', lineHeight: '1.5' } },
        React.createElement(TextField, { fullWidth: true, variant: "standard", value: key, onChange: (e) => {
                setKey(e.target.value);
                debouncedApplyValue({
                    ...item,
                    value: { ...item.value, key: e.target.value },
                });
            }, label: "key" }),
        React.createElement(TextField, { fullWidth: true, variant: "standard", value: value, onChange: (e) => {
                setValue(e.target.value);
                debouncedApplyValue({
                    ...item,
                    value: { ...item.value, value: e.target.value },
                });
            }, label: "value" })));
}
const customRenderCell = (params) => {
    const value = params.formattedValue || params.value;
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    return (React.createElement("div", { style: {
            width: '100%',
            height: '100%',
            cursor: 'pointer'
        } },
        React.createElement(Stack, { justifyContent: "center", height: "100%", width: "fit-content", overflow: "visible" },
            React.createElement(Tooltip, { placement: 'top', title: "Copied to clipboard !", open: open, disableFocusListener: true, disableHoverListener: true, disableTouchListener: true },
                React.createElement(Typography, { onClick: async (e) => {
                        navigator.clipboard.writeText(value);
                        setOpen(true);
                        e.stopPropagation();
                        e.preventDefault();
                        await delay(2000);
                        handleTooltipClose();
                    }, sx: { cursor: 'copy' } }, value)))));
};
const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
        field: 'platform_user_id', headerName: 'Platform User ID', width: 200,
        renderCell: customRenderCell
    },
    {
        field: 'platform_code', headerName: 'Platform Code', width: 160,
        renderCell: customRenderCell
    },
    {
        field: 'game_code', headerName: 'Game Code', width: 130,
        renderCell: customRenderCell
    },
    {
        field: 'last_login',
        headerName: 'Last Login',
        width: 160,
        type: 'dateTime',
        valueFormatter: (value) => {
            return Convert.numberToHumanDate(value, 'MM/YY/DD [at] HH:mm');
        },
        renderCell: customRenderCell
    },
    {
        field: 'metadata',
        headerName: 'Metadata',
        width: 200,
        type: 'custom',
        renderCell: (params) => {
            const metadata = params.row.metadata;
            return (React.createElement(Stack, { direction: "row", gap: "1rem" }, Object.keys(metadata).map((key, index) => (React.createElement(Badge, { key: index },
                React.createElement("span", null,
                    key,
                    ": "),
                React.createElement("span", null, metadata[key]))))));
        },
        filterOperators: getGridStringOperators()
            .splice(0, 1)
            .map((operator) => ({
            ...operator,
            InputComponent: MetadataInputValue,
        })),
    },
];
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiBadge-root': {
        backgroundColor: 'transparent',
        padding: 0,
        color: 'white',
    },
}));
function DataTable({ onRowClick }) {
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 15,
    });
    const [filterModel, setFilterModel] = React.useState({ items: [] });
    const [queryOptions, setQueryOptions] = React.useState({
        ...paginationModel,
    });
    const { isLoading, rows, pageInfo, error } = usePlayerData(queryOptions);
    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCount` from being undefined during the loading
    const rowCountRef = React.useRef(pageInfo?.totalRowCount || 0);
    const rowCount = React.useMemo(() => {
        if (pageInfo?.totalRowCount !== undefined) {
            rowCountRef.current = pageInfo.totalRowCount;
        }
        return rowCountRef.current;
    }, [pageInfo?.totalRowCount]);
    const handlePaginationModelChange = React.useCallback((paginationModel) => {
        // Here you save the data you need from the pagination model
        setPaginationModel(paginationModel);
        setQueryOptions({ ...queryOptions, ...paginationModel });
    }, [queryOptions]);
    const handleSortModelChange = React.useCallback((sortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ ...queryOptions, sortModel: [...sortModel] });
    }, [queryOptions]);
    const handleFilterModelChange = React.useCallback((filterModel) => {
        setFilterModel(filterModel);
        setQueryOptions({ ...queryOptions, filterModel: { ...filterModel } });
    }, [queryOptions]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ClickAwayListener, { onClickAway: () => { setSelectionModel([]); } },
            React.createElement(StyledDataGrid, { autoHeight: true, rows: rows, columns: [...columns], rowCount: rowCount, pageSizeOptions: [5, 15, 25, 50, 100], sortingMode: "server", onSortModelChange: handleSortModelChange, filterMode: "server", filterModel: filterModel, onFilterModelChange: handleFilterModelChange, paginationMode: "server", paginationModel: paginationModel, onPaginationModelChange: handlePaginationModelChange, loading: isLoading, onRowClick: onRowClick, rowSelectionModel: selectionModel, onRowSelectionModelChange: (selectionModel) => setSelectionModel(selectionModel), slots: {
                    toolbar: GridToolbar,
                }, hideFooterSelectedRowCount: true, sx: { border: "none" } })),
        error && React.createElement(React.Fragment, null, `${error}`)));
}
export default DataTable;
